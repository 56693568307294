:root {
  --primary-text-color: #333;
  --second-text-color: #fff;
  --back-color: #fffaf2;
  --second-back-color: #f5d6ab;
  --default-bg-color: #fff;
  --back-gradient: linear-gradient( 324deg, var(--hover-color), var(--second-back-color));
  --hover-color: #c66c49;
}

.switcher__radio {
  filter: invert();
}

.switcher__radio:checked {
  filter: invert(0);
}

.switcher__status {
  filter: invert();
}

/*# sourceMappingURL=index.d55a3940.css.map */
